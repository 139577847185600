import React, { useEffect, useState } from 'react';
import { FaBook } from 'react-icons/fa';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import DialogComponent from '../components/Dialog';
import { useLabContext } from '../context/LabContext';

interface LabInstructionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LabInstructionsDialog: React.FC<LabInstructionsDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { activeLabId, labSetInstance } = useLabContext();
  const [instructions, setInstructions] = useState<string | null>(null);

  useEffect(() => {
    const findInstructions = () => {
      if (!activeLabId) return;
      if (!labSetInstance) return;

      for (const lab of labSetInstance.labs) {
        if (lab.labId === activeLabId) {
          setInstructions(lab.instructions);
          break;
        }
      }
    };

    findInstructions();
  }, [activeLabId, labSetInstance]);

  const close = () => {
    onClose();
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={close}
      title={
        <>
          <FaBook className="mr-2" />
          Lab Instructions
        </>
      }
    >
      {instructions ? (
        <Markdown remarkPlugins={[remarkGfm]}>{instructions}</Markdown>
      ) : (
        <p className="text-lg text-gray-500">
          No instructions available for the active lab.
        </p>
      )}
    </DialogComponent>
  );
};

export default LabInstructionsDialog;
