// beaker/src/pages/PrimaryLab/components/ShareLabDialog.tsx

import { Button } from '@headlessui/react';
import React, { useState } from 'react';
import { FaFlask, FaClipboard } from 'react-icons/fa';

import useApiClient from '../api/apiClient';
import DialogComponent from '../components/Dialog';
import { useLabContext } from '../context/LabContext';
import { useToast, ToastType } from '../context/ToastContext';

interface ShareLabDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShareLabDialog: React.FC<ShareLabDialogProps> = ({ isOpen, onClose }) => {
  const { labSetInstance, activeLabId } = useLabContext();
  const { shareLab } = useApiClient();
  const { showToast } = useToast();

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [shareLink, setShareLink] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const handleGenerateLink = async () => {
    if (!labSetInstance || !activeLabId) {
      showToast('No active lab to share.', ToastType.FAILURE);
      return;
    }

    setIsGenerating(true);
    setShareLink(null);

    try {
      const response = await shareLab(
        labSetInstance.labSetInstanceId,
        readOnly,
      );

      if (response && response.labSetInstanceId) {
        const base_url = process.env.REACT_APP_WEB_APP_BASE_URL;
        setShareLink(`${base_url}/join/${response.labSetInstanceId}`);
      } else {
        throw new Error('Invalid response from server.');
      }
    } catch (error) {
      console.error('Error generating share link:', error);
      showToast('Failed to generate shareable URL.', ToastType.FAILURE);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopy = async () => {
    if (!shareLink) return;

    try {
      setIsCopying(true);
      await navigator.clipboard.writeText(shareLink);
      showToast('Link copied to clipboard!', ToastType.SUCCESS);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      showToast('Failed to copy the link.', ToastType.FAILURE);
    } finally {
      setIsCopying(false);
    }
  };

  const handleClose = () => {
    setReadOnly(true);
    setShareLink(null);
    setIsGenerating(false);
    setIsCopying(false);
    onClose();
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <div className="flex items-center text-gray-900">
          <FaFlask className="mr-2 text-blue-600 text-2xl" />
          <span className="text-2xl font-semibold">Share Lab</span>
        </div>
      }
      size="compact"
    >
      <div className="space-y-6">
        <div className="flex items-center">
          <input
            id="readOnly"
            type="checkbox"
            checked={readOnly}
            onChange={() => setReadOnly(!readOnly)}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          <label
            htmlFor="readOnly"
            className="ml-2 block text-lg text-gray-700"
          >
            Share as Read-Only
          </label>
        </div>

        <Button
          className={`w-full flex items-center justify-center px-4 py-2 text-lg font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700 focus:outline-none ${
            isGenerating ? 'opacity-75 cursor-not-allowed' : ''
          }`}
          onClick={handleGenerateLink}
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating...' : 'Generate Shareable URL'}
        </Button>

        {shareLink && (
          <div className="flex mt-4">
            <input
              type="text"
              readOnly
              value={shareLink}
              className="flex-grow px-4 py-2 text-lg text-gray-700 bg-gray-100 border border-gray-300 rounded-l-md focus:outline-none"
            />
            <button
              onClick={handleCopy}
              className="px-4 text-white bg-primary-600 border border-transparent rounded-r-md hover:bg-primary-700 focus:outline-none flex items-center justify-center"
              disabled={isCopying}
            >
              <FaClipboard className="text-white text-xl" />
            </button>
          </div>
        )}
      </div>
    </DialogComponent>
  );
};

export default ShareLabDialog;
