import React, { useState, useEffect, useRef } from 'react';

import mantaray1 from '../assets/images/mantaray1.webp';
import mantaray2 from '../assets/images/mantaray2.webp';
import mantaray3 from '../assets/images/mantaray3.webp';

const mantarayImages = [mantaray1, mantaray2, mantaray3];

interface LoadingScreenProps {
  message: string;
  loadingTimeSeconds?: number;
}

interface MantarayObject {
  id: number;
  x: number;
  y: number;
  dy: number;
  dx: number;
  angle: number;
  src: string;
  size: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  message,
  loadingTimeSeconds,
}) => {
  const [objects, setObjects] = useState<MantarayObject[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const objectCounter = useRef(0);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);

  useEffect(() => {
    const mantarayFrequency = 1500;
    const createMantaray = () => {
      const randomImage =
        mantarayImages[Math.floor(Math.random() * mantarayImages.length)];
      const randomSize = Math.random() + 0.5;
      setObjects((prevObjects) => [
        ...prevObjects,
        {
          id: objectCounter.current++,
          x: Math.random() * window.innerWidth,
          y: window.innerHeight,
          dy: -(0.5 + Math.random() * 1.5),
          dx: (Math.random() * 1 - 0.5) * 1,
          angle: Math.random() * 360,
          src: randomImage,
          size: randomSize,
        },
      ]);
    };

    const startMantarayInterval = () => {
      if (intervalIdRef.current === null) {
        intervalIdRef.current = setInterval(createMantaray, mantarayFrequency);
      }
    };

    const clearMantarayInterval = () => {
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearMantarayInterval();
      } else {
        startMantarayInterval();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Start the interval initially
    startMantarayInterval();

    return () => {
      clearMantarayInterval();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const updateObjects = () => {
      setObjects((prevObjects) =>
        prevObjects
          .map((obj) => ({
            ...obj,
            x: obj.x + obj.dx,
            y: obj.y + obj.dy,
            angle: Math.atan2(obj.dy, obj.dx) * (180 / Math.PI) + 90,
          }))
          .filter(
            (obj) =>
              obj.y > -256 && obj.x > -256 && obj.x < window.innerWidth + 256,
          ),
      );
      animationFrameIdRef.current = requestAnimationFrame(updateObjects);
    };

    const startAnimation = () => {
      if (animationFrameIdRef.current === null) {
        animationFrameIdRef.current = requestAnimationFrame(updateObjects);
      }
    };

    const stopAnimation = () => {
      if (animationFrameIdRef.current !== null) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        stopAnimation();
      } else {
        startAnimation();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Start the animation initially
    startAnimation();

    return () => {
      stopAnimation();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!loadingTimeSeconds) return;
    const startTime = Date.now();
    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const newProgress = Math.min(
        (elapsed / (loadingTimeSeconds * 1000)) * 100,
        100,
      );
      setProgress(newProgress);
      if (newProgress < 100) requestAnimationFrame(updateProgress);
    };
    requestAnimationFrame(updateProgress);
  }, [loadingTimeSeconds]);

  return (
    <div className="loading-screen">
      <div className="game-container relative">
        {objects.map((obj) => (
          <img
            key={obj.id}
            src={obj.src}
            alt="Mantaray"
            className="object absolute"
            style={{
              left: `${obj.x}px`,
              top: `${obj.y}px`,
              transform: `rotate(${obj.angle}deg) scale(${obj.size})`,
            }}
          />
        ))}
        <div className="loading-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center z-10">
          <div className="loading-text text-2xl font-bold mb-6 text-white">
            {message}
          </div>
        </div>
        {loadingTimeSeconds !== undefined && (
          <div
            className="loading-bar-container absolute left-1/2 transform -translate-x-1/2 w-4/5 max-w-md z-20"
            style={{ top: 'calc(50% + 8rem)' }}
          >
            <div className="relative h-6 bg-gray-200 rounded-full overflow-hidden shadow-inner border border-white">
              <div
                className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-purple-500 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="mt-2 text-right text-white font-semibold">
              {Math.round(progress)}%
            </div>
            {progress === 100 && (
              <div className="mt-4 text-center text-gray-400 font-medium">
                Operation is taking longer than normal to complete
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
