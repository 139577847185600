import { TAuthConfig } from 'react-oauth2-code-pkce';

const authConfig: TAuthConfig = {
  autoLogin: false,
  onRefreshTokenExpire: () => false,
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  authorizationEndpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT || '',
  tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
  scope: process.env.REACT_APP_SCOPE || '',
  logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
  preLogin: () => {
    const originalUrl = window.location.pathname + window.location.search;
    localStorage.setItem('originalUrl', originalUrl);
  },
  postLogin: () => {
    const originalUrl = localStorage.getItem('originalUrl');
    if (originalUrl) {
      window.location.href = originalUrl;
      localStorage.removeItem('originalUrl');
    } else {
      window.location.href = '/';
    }
  },
};

export default authConfig;
