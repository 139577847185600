import React, { useState, useRef, useEffect, useCallback } from 'react';

interface GuacamoleConnectionProps {
  guacamoleBaseUrl?: string | null;
  workstationId?: string | null;
  token?: string | null;
}

const GuacamoleConnection: React.FC<GuacamoleConnectionProps> = ({
  guacamoleBaseUrl,
  workstationId,
  token,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const base64urlEncode = (value: string): string => {
    const encoded = btoa(value);
    return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };

  const createClientIdentifier = useCallback(
    (id: string, type: string, dataSource: string): string => {
      const combinedStr = `${id}\0${type}\0${dataSource}`;
      return base64urlEncode(combinedStr);
    },
    [],
  );

  const constructGuacamoleUrl = useCallback(() => {
    if (!guacamoleBaseUrl || !workstationId || !token) return '';

    const clientId = createClientIdentifier(workstationId, 'c', 'json');
    return `${guacamoleBaseUrl}/#/client/${clientId}?token=${token}`;
  }, [guacamoleBaseUrl, workstationId, token, createClientIdentifier]);

  const previousUrlRef = useRef<string | null>(null);

  useEffect(() => {
    const url = constructGuacamoleUrl();
    if (iframeRef.current && url && url !== previousUrlRef.current) {
      iframeRef.current.src = url;
      previousUrlRef.current = url;
    }
  }, [constructGuacamoleUrl]);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleIframeError = () => {
    setLoading(false);
  };

  const focusIframe = useCallback(() => {
    const isDialogOpen = !!document.querySelector('#headlessui-portal-root');

    if (!isDialogOpen && iframeRef.current) {
      parentRef.current?.focus();
      iframeRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleIframeLoad);
      iframe.addEventListener('error', handleIframeError);
    }

    document.addEventListener('click', focusIframe);
    document.addEventListener('keydown', focusIframe);

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleIframeLoad);
        iframe.removeEventListener('error', handleIframeError);
      }
      document.removeEventListener('click', focusIframe);
      document.removeEventListener('keydown', focusIframe);
    };
  }, [focusIframe]);

  return (
    <div
      ref={parentRef}
      className="relative flex justify-center items-center bg-black"
      style={{
        width: '100%',
        height: 'calc(100vh - 4rem)',
      }}
      onClick={focusIframe}
      tabIndex={-1}
    >
      <iframe
        ref={iframeRef}
        className="w-full h-full border-none"
        title="Guacamole Connection"
        allow="clipboard-write; clipboard-read; fullscreen"
        tabIndex={0}
        onClick={focusIframe}
      />
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-black">
          <div className="w-24 h-24 border-8 border-t-8 border-gray-200 border-t-black rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default GuacamoleConnection;
