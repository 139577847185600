import React from 'react';
import { Outlet } from 'react-router-dom';

import { LabProvider } from '../context/LabContext';

const LabControlContext: React.FC = () => {
  // LabProvider gives everything in the Outlet easy read/write access to information
  // about a lab, active lab, active workstation, etc.
  return (
    <LabProvider>
      <Outlet />
    </LabProvider>
  );
};

export default LabControlContext;
