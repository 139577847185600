import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import { useToast, ToastData, ToastType } from '../context/ToastContext';

const ToastContainer: React.FC = () => {
  const { toasts, hideToast } = useToast();

  return (
    <div className="fixed top-[90px] left-1/2 transform -translate-x-1/2 space-y-4 z-50">
      {toasts.map((toast) => (
        <div key={toast.id} className="toast-wrapper">
          <Toast toast={toast} onHide={() => hideToast(toast.id)} />
        </div>
      ))}
    </div>
  );
};

interface ToastProps {
  toast: ToastData;
  onHide: () => void;
}

const Toast: React.FC<ToastProps> = ({ toast, onHide }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const bgColor = {
    [ToastType.SUCCESS]: 'bg-emerald-500',
    [ToastType.FAILURE]: 'bg-rose-500',
    [ToastType.WARNING]: 'bg-amber-500',
  }[toast.type];

  const fadeOutToast = () => {
    setIsExiting(true);
    setTimeout(onHide, 500);
  };

  useEffect(() => {
    if (isHovered) return;

    const timer = setTimeout(() => {
      fadeOutToast();
    }, toast.duration);

    return () => clearTimeout(timer);
  }, [isHovered]);

  return (
    <div
      className={`relative p-4 rounded-lg text-white ${bgColor} shadow-xl 
                  transition-opacity ease-in-out duration-500
                  ${isExiting ? 'toast-exit' : 'toast-enter'}
                  w-[500px] cursor-pointer flex`}
      onClick={fadeOutToast}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex-grow">
        <p className="text-lg">{toast.message}</p>
      </div>
      <div className="w-10 ms-2 flex items-start justify-center">
        <button
          onClick={(e) => {
            e.stopPropagation();
            fadeOutToast();
          }}
          className="text-white text-2xl"
          aria-label="Close"
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default ToastContainer;
