import React, { useCallback, useEffect, useMemo } from 'react';

import { useLabContext } from '../context/LabContext';
import { ResourceState, Workstation } from '../models';

import WorkstationOption from './WorkstationOption';

const WorkstationSelect: React.FC = () => {
  const {
    labSetInstance,
    activeLabId,
    activeWorkstation,
    isJoinedLab,
    setActiveWorkstation,
    setLoadingMessage,
  } = useLabContext();

  if (!labSetInstance || !labSetInstance.workstations || !activeLabId) {
    return null;
  }

  const activeLab = labSetInstance.labs.find(
    (lab) => lab.labId === activeLabId,
  );

  if (!activeLab) {
    return null;
  }

  const workstationOptions = useMemo(
    () =>
      activeLab.workstationIds
        .map((workstationId) =>
          labSetInstance.workstations.find(
            (workstation) => workstation.workstationId === workstationId,
          ),
        )
        .filter(
          (workstation): workstation is Workstation =>
            workstation !== undefined,
        ),
    [activeLab.workstationIds, labSetInstance.workstations],
  );

  const allWorkstationsRecreating = workstationOptions.every(
    (workstation) => workstation.state === ResourceState.RECREATING,
  );

  const handleSetActiveWorkstation = useCallback(
    (workstationId: string) => {
      if (activeWorkstation === workstationId || isJoinedLab) {
        return;
      }
      setActiveWorkstation(workstationId);
    },
    [activeWorkstation, setActiveWorkstation, isJoinedLab],
  );

  useEffect(() => {
    if (allWorkstationsRecreating) {
      setLoadingMessage('Recreating your workstation');
      return;
    }

    // Clear the loading message if not all workstations are recreating
    setLoadingMessage('');

    const deployedWorkstations = workstationOptions.filter(
      (workstation) => workstation.state === ResourceState.DEPLOYED,
    );

    // Case 1: No active workstation set
    if (!activeWorkstation && deployedWorkstations.length > 0) {
      handleSetActiveWorkstation(deployedWorkstations[0].workstationId);
      return;
    }

    // Case 2: Active workstation is set but not deployed
    const activeWorkstationObject = workstationOptions.find(
      (workstation) => workstation.workstationId === activeWorkstation,
    );

    if (
      activeWorkstation &&
      activeWorkstationObject?.state !== ResourceState.DEPLOYED &&
      deployedWorkstations.length > 0
    ) {
      handleSetActiveWorkstation(deployedWorkstations[0].workstationId);
    }
  }, [
    allWorkstationsRecreating,
    setLoadingMessage,
    workstationOptions,
    activeWorkstation,
    handleSetActiveWorkstation,
  ]);

  // If all workstations are recreating, do not render the component
  if (allWorkstationsRecreating) {
    return null;
  }

  // If there's only one workstation, do not render the selector
  const multipleWorkstations = workstationOptions.length > 1;

  return multipleWorkstations ? (
    <div
      className={`relative flex items-center h-full gap-2 ${isJoinedLab ? 'cursor-not-allowed' : ''}`}
    >
      {workstationOptions.map((workstation) => (
        <WorkstationOption
          key={workstation.workstationId}
          workstation={workstation}
          isActive={activeWorkstation === workstation.workstationId}
          onClick={() => handleSetActiveWorkstation(workstation.workstationId)}
          disabled={isJoinedLab}
        />
      ))}
    </div>
  ) : null;
};

export default WorkstationSelect;
