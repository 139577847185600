import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FaFlask, FaSearch, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import useApiClient, { UnauthorizedError } from '../../../api/apiClient';
import { LabSetInstance } from '../../../models/LabSetInstance';

const Title: React.FC = () => (
  <h1 className="text-2xl font-bold mb-4 text-blue-800 flex items-center">
    <FaFlask className="mr-2" />
    Live Labs
  </h1>
);

const LiveLabs: React.FC = () => {
  const [labSetInstances, setLabSetInstances] = useState<LabSetInstance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const { getLabSetInstances, shareLab } = useApiClient();
  const navigate = useNavigate();

  const [loadingButtons, setLoadingButtons] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const fetchLabSetInstances = async () => {
      try {
        const instances = await getLabSetInstances();
        setLabSetInstances(instances);
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          navigate('/unauthorized');
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLabSetInstances();
  }, [navigate]);

  const handleJoinLab = useCallback(
    async (labSetInstanceId: string, readOnly: boolean) => {
      const key = `${labSetInstanceId}-${readOnly}`;
      setLoadingButtons((prev) => ({ ...prev, [key]: true }));
      try {
        await shareLab(labSetInstanceId, readOnly);
        const url = `/join/${labSetInstanceId}`;
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingButtons((prev) => ({ ...prev, [key]: false }));
      }
    },
    [shareLab],
  );

  const columns = useMemo<ColumnDef<LabSetInstance>[]>(
    () => [
      {
        accessorKey: 'labSetId',
        header: () => <div className="flex items-center">Lab Set ID</div>,
      },
      {
        accessorKey: 'userId',
        header: () => <div className="flex items-center">User ID</div>,
      },
      {
        accessorKey: 'state',
        header: () => <div className="flex items-center">State</div>,
      },
      {
        accessorKey: 'expires',
        header: () => <div className="flex items-center">Expires</div>,
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return new Date(value * 1000).toLocaleString();
        },
      },
      {
        accessorKey: 'labSetInstanceId',
        header: 'Actions',
        enableSorting: false,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          const collaborativeKey = `${value}-false`;
          const readOnlyKey = `${value}-true`;
          const isCollaborativeLoading =
            loadingButtons[collaborativeKey] || false;
          const isReadOnlyLoading = loadingButtons[readOnlyKey] || false;

          return (
            <div className="flex space-x-2">
              <button
                onClick={() => handleJoinLab(value, false)}
                disabled={isCollaborativeLoading}
                className={`flex items-center bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded transition ${
                  isCollaborativeLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isCollaborativeLoading && (
                  <span className="spinner mr-2"></span>
                )}
                Join Collaborative
              </button>
              <button
                onClick={() => handleJoinLab(value, true)}
                disabled={isReadOnlyLoading}
                className={`flex items-center bg-gray-500 hover:bg-blue-600 text-white py-1 px-3 rounded transition ${
                  isReadOnlyLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isReadOnlyLoading && <span className="spinner mr-2"></span>}
                Join Read-Only
              </button>
            </div>
          );
        },
      },
    ],
    [handleJoinLab, loadingButtons],
  );

  const table = useReactTable({
    data: labSetInstances,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
  });

  if (loading) {
    return (
      <div className="p-6 bg-blue-50 rounded shadow">
        <Title />
        <div className="animate-pulse">
          <div className="h-6 bg-blue-200 rounded mb-4 w-1/3"></div>
          {[...Array(5)].map((_, index) => (
            <div key={index} className="h-6 bg-blue-100 rounded mb-2"></div>
          ))}
        </div>
      </div>
    );
  }

  if (labSetInstances.length === 0) {
    return (
      <div className="p-6 bg-blue-50 rounded shadow">
        <Title />
        <p className="text-blue-700">No live labs available.</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-blue-100 rounded shadow-lg relative">
      <Title />

      <div className="mb-4 flex items-center">
        <FaSearch className="text-blue-500 mr-2" />
        <input
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="border border-blue-300 bg-white text-blue-800 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <table className="min-w-full bg-white shadow-md rounded">
        <thead className="bg-blue-300">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="py-2 px-4 border-b text-left cursor-pointer text-blue-700"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="flex items-center">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === 'desc' ? (
                        <FaCaretDown className="ml-2 self-center" />
                      ) : (
                        <FaCaretUp className="ml-2 self-center" />
                      )
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i) => (
            <tr
              key={row.id}
              className={i % 2 === 0 ? 'bg-white' : 'bg-blue-50'}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-2 px-4 border-b text-blue-800">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LiveLabs;
