import React, { useEffect, useContext } from 'react';
import { AuthProvider, AuthContext } from 'react-oauth2-code-pkce';
import { Outlet, useLocation } from 'react-router-dom';

import authConfig from '../authConfig';
import ToastContainer from '../components/ToastContainer';
import { ThemeProvider } from '../context/ThemeContext';
import { ToastProvider } from '../context/ToastContext';
import { WebSocketProvider } from '../context/WebSocketContext';

const AuthCheck: React.FC = () => {
  const location = useLocation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('error')) {
      console.error('Auth error:', params.get('error_description'));
    } else if (!auth.token && !auth.loginInProgress) {
      console.log('No token found, initiating login');
      auth.logIn();
    }
  }, [location, auth]);

  if (auth.loginInProgress) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
};

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      console.error('Caught error:', error);
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return <div>Something went wrong. Please try refreshing the page.</div>;
  }

  return <>{children}</>;
};

const Root: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthProvider authConfig={authConfig}>
        <ThemeProvider>
          <ToastProvider>
            <WebSocketProvider>
              <div className="flex flex-col h-screen">
                <AuthCheck />
                <ToastContainer />
              </div>
            </WebSocketProvider>
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default Root;
