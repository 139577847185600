import { debounce } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';

import { useToast, ToastType } from '../context/ToastContext';
import {
  LabViewState,
  WebSocketMessage,
  WebSocketEventType,
  WebSocketLabStateChangedData,
  WebSocketErrorData,
} from '../models';

const PING_INTERVAL_MS = 10000;
const PONG_TIMEOUT_MS = 15000;
const MAX_RETRY_DURATION_MS = 30000;

interface LabEventHandlers {
  onLabStateChanged?: (data: WebSocketLabStateChangedData) => void;
}

export interface WebSocketContextState {
  isConnected: boolean;
  subscribeToLabEvents: (
    labSetInstanceId: string,
    handlers: LabEventHandlers,
  ) => void;
  unsubscribeFromLabEvents: (labSetInstanceId: string) => void;
  updateLabViewState: (
    labSetInstanceId: string,
    labViewState: LabViewState,
  ) => void;
}

export const WebSocketContext = createContext<WebSocketContextState | null>(
  null,
);

export function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const { token, error } = useContext<IAuthContext>(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [retryCount, setRetryCount] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<number | null>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const pongTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const labEventHandlersRef = useRef<Record<string, LabEventHandlers>>({});

  const connect = () => {
    if (!token) return;

    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      const url = `${process.env.REACT_APP_WEBSOCKET_BASE_URL}?token=${token}`;
      wsRef.current = new WebSocket(url);
    }

    wsRef.current.onopen = () => {
      console.log('WebSocket connected');
      setRetryCount(0);
      setIsConnected(true);
      startPingInterval();
      if (reconnectTimeoutRef.current !== null) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      // Resubscribe to all lab events upon reconnection
      Object.keys(labEventHandlersRef.current).forEach((labSetInstanceId) => {
        const subscribeMessage: WebSocketMessage = {
          data: {
            eventType: WebSocketEventType.SUBSCRIBE_TO_LAB_CHANGES,
            labSetInstanceId: labSetInstanceId,
          },
        };
        wsRef.current?.send(JSON.stringify(subscribeMessage));
      });
    };

    wsRef.current.onclose = (event) => {
      console.log('WebSocket disconnected', event);
      setIsConnected(false);
      stopPingInterval();
      scheduleReconnect();
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current.onmessage = handleWebSocketMessage;
  };

  const scheduleReconnect = () => {
    const timeout = Math.min(1000 * 2 ** retryCount, MAX_RETRY_DURATION_MS);
    console.log(`Attempting to reconnect in ${timeout / 1000} seconds`);
    reconnectTimeoutRef.current = window.setTimeout(() => {
      reconnectTimeoutRef.current = null;
      console.log('Reconnecting WebSocket');
      connect();
      setRetryCount((prev) => prev + 1);
    }, timeout);
  };

  const startPingInterval = () => {
    pingIntervalRef.current = setInterval(sendPingMessage, PING_INTERVAL_MS);
  };

  const stopPingInterval = () => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
  };

  const sendPingMessage = () => {
    console.log('[WebSocket] Sending PING');
    const pingMessage: WebSocketMessage = {
      data: { eventType: WebSocketEventType.PING },
    };
    wsRef.current?.send(JSON.stringify(pingMessage));
    setPongTimeout();
  };

  const setPongTimeout = () => {
    if (pongTimeoutRef.current) return;
    pongTimeoutRef.current = setTimeout(() => {
      console.error(
        '[WebSocket] PONG not received within 15 seconds. Disconnecting...',
      );
      wsRef.current?.close();
    }, PONG_TIMEOUT_MS);
  };

  const clearPongTimeout = () => {
    if (pongTimeoutRef.current) {
      clearTimeout(pongTimeoutRef.current);
      pongTimeoutRef.current = null;
    }
  };

  const handlePongMessage = () => {
    clearPongTimeout();
  };

  const handleLabStateChanged = (data: WebSocketLabStateChangedData) => {
    // Cancel any pending lab view state updates
    updateLabViewState.cancel();

    const handlers = labEventHandlersRef.current[data.labSetInstanceId];
    if (handlers && handlers.onLabStateChanged) {
      handlers.onLabStateChanged(data);
    }
  };

  const handleUnauthorizedMessage = () => {
    console.warn('[WebSocket] Unauthorized access');
    showToast(
      'You are not authorized to perform that action.',
      ToastType.WARNING,
    );
    navigate('/');
  };

  const handleLabErrorMessage = (data: WebSocketErrorData) => {
    console.error('[WebSocket] Error:', data);
    showToast(`An error occurred: ${data.message}`, ToastType.FAILURE);
  };

  const handleWebSocketMessage = (event: MessageEvent) => {
    const message: WebSocketMessage = JSON.parse(event.data);
    console.log(`[WebSocket] Received message: ${message.data.eventType}`);

    switch (message.data.eventType) {
      case WebSocketEventType.PONG:
        handlePongMessage();
        break;
      case WebSocketEventType.LAB_STATE_CHANGED:
        handleLabStateChanged(message.data);
        break;
      case WebSocketEventType.UNAUTHORIZED:
        handleUnauthorizedMessage();
        break;
      case WebSocketEventType.ERROR:
        handleLabErrorMessage(message.data);
        break;
      default:
        console.log(
          '[WebSocket] Unhandled message type:',
          message.data.eventType,
        );
    }
  };

  useEffect(() => {
    connect();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      if (reconnectTimeoutRef.current !== null) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
      stopPingInterval();
      clearPongTimeout();
    };
  }, [token]);

  useEffect(() => {
    if (error) {
      console.log('Auth error:', error);
      console.log('Reloading page...');
      window.location.reload();
    }
  }, [error]);

  const subscribeToLabEvents = (
    labSetInstanceId: string,
    handlers: LabEventHandlers,
  ) => {
    labEventHandlersRef.current[labSetInstanceId] = handlers;

    const sendSubscribeMessage = () => {
      const subscribeMessage: WebSocketMessage = {
        data: {
          eventType: WebSocketEventType.SUBSCRIBE_TO_LAB_CHANGES,
          labSetInstanceId: labSetInstanceId,
        },
      };
      wsRef.current?.send(JSON.stringify(subscribeMessage));
    };

    if (wsRef.current?.readyState === WebSocket.OPEN) {
      sendSubscribeMessage();
    } else {
      // Wait for the connection to open before sending the message
      const interval = setInterval(() => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
          sendSubscribeMessage();
          clearInterval(interval);
        }
      }, 100);
    }
  };

  const unsubscribeFromLabEvents = (labSetInstanceId: string) => {
    delete labEventHandlersRef.current[labSetInstanceId];
  };

  const sendLabViewStateUpdate = useCallback(
    (labSetInstanceId: string, labViewState: LabViewState) => {
      const message: WebSocketMessage = {
        data: {
          eventType: WebSocketEventType.UPDATE_LAB_VIEW_STATE,
          labSetInstanceId,
          labViewState,
        },
      };
      wsRef.current?.send(JSON.stringify(message));
    },
    [],
  );

  const updateLabViewState = useRef(
    debounce(
      (labSetInstanceId: string, labViewState: LabViewState) => {
        sendLabViewStateUpdate(labSetInstanceId, labViewState);
      },
      1000,
      { leading: false, trailing: true },
    ),
  ).current;

  const contextValue: WebSocketContextState = {
    isConnected,
    subscribeToLabEvents,
    unsubscribeFromLabEvents,
    updateLabViewState,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
}
