// beaker/src/pages/PrimaryLab/JoinedLabPage.tsx

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LabControlHeader from '../components/LabControlHeader';
import LabView from '../components/LabView';
import { useLabContext } from '../context/LabContext';
import { useWebSocket } from '../context/WebSocketContext';
const JoinedLabPage: React.FC = () => {
  const { labSetInstanceId } = useParams();

  const { setLabSetInstanceId, setLoadingMessage, setIsJoinedLab } =
    useLabContext();
  const { unsubscribeFromLabEvents } = useWebSocket();

  useEffect(() => {
    if (!labSetInstanceId) return;

    setIsJoinedLab(true);
    setLoadingMessage('Initializing');

    setLabSetInstanceId(labSetInstanceId);

    return () => {
      if (labSetInstanceId) {
        unsubscribeFromLabEvents(labSetInstanceId);
      }
    };
  }, [labSetInstanceId]);

  return (
    <>
      <LabControlHeader />
      <LabView />
    </>
  );
};

export default JoinedLabPage;
