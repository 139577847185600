import React from 'react';

interface HeaderButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({
  icon,
  label,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      className={`flex items-center px-4 py-2 rounded-lg shadow transition duration-200 ${
        disabled
          ? 'bg-secondary-400 cursor-not-allowed'
          : 'bg-primary-600 hover:bg-primary-500 text-white'
      }`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <span className="mr-2">{icon}</span>
      {label}
    </button>
  );
};

export default HeaderButton;
