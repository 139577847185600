// beaker/src/pages/PrimaryLab/PrimaryLabPage.tsx

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import useApiClient, {
  NotFoundError,
  BadRequestError,
  TooManyRequestsError,
} from '../api/apiClient';
import LabControlHeader from '../components/LabControlHeader';
import LabView from '../components/LabView';
import { useLabContext } from '../context/LabContext';
import { useToast, ToastType } from '../context/ToastContext';
import { LabSetInstance } from '../models';

const PrimaryLabPage: React.FC = () => {
  const { findLabSetInstance, createLabSetInstance } = useApiClient();
  const { labSetId: rawLabSetId } = useParams<Params>();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { setLabSetInstanceId, setLoadingMessage } = useLabContext();

  interface Params {
    labSetId?: string;
    [key: string]: string | undefined;
  }

  const labSetId = rawLabSetId?.toLowerCase();

  useEffect(() => {
    if (!labSetId) return;
    setLoadingMessage('Initializing');

    const fetchOrCreateLabSetInstance = async () => {
      try {
        const instance = await findLabSetInstance(labSetId);
        handleInstance(instance);
      } catch (error) {
        await handleFindInstanceError(error);
      }
    };

    const handleFindInstanceError = async (error: any) => {
      if (error instanceof NotFoundError) {
        setLoadingMessage('Creating your lab');
        try {
          const instance = await createLabSetInstanceWithRetry(labSetId);
          handleInstance(instance);
        } catch (createError) {
          if (createError instanceof TooManyRequestsError) {
            showToast(
              'Too many requests, please try again later.',
              ToastType.FAILURE,
              30000,
            );
          } else if (createError instanceof BadRequestError) {
            navigate(`/?error=Lab code '${labSetId}' not found`);
          } else {
            showToast(
              'An unexpected error occurred.',
              ToastType.FAILURE,
              30000,
            );
          }
          navigate('/');
        }
      } else if (error instanceof BadRequestError) {
        setLoadingMessage('');
        navigate(`/?error=Lab code '${labSetId}' not found`);
      } else {
        showToast('An unexpected error occurred.', ToastType.FAILURE, 30000);
        navigate('/');
      }
    };

    const createLabSetInstanceWithRetry = async (labSetId: string) => {
      const MAX_RETRIES = 3;
      const RETRY_DELAY_MS = 1000; // 1 second delay
      let retries = 0;

      while (retries < MAX_RETRIES) {
        try {
          const instance = await createLabSetInstance(labSetId);
          return instance;
        } catch (error) {
          if (error instanceof TooManyRequestsError) {
            retries++;
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
          } else {
            throw error;
          }
        }
      }
      throw new TooManyRequestsError();
    };

    const handleInstance = (instance: LabSetInstance) => {
      setLabSetInstanceId(instance.labSetInstanceId);
    };

    fetchOrCreateLabSetInstance();
  }, [labSetId]);

  return (
    <>
      <LabControlHeader />
      <LabView />
    </>
  );
};

export default PrimaryLabPage;
