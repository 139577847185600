import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { FaChevronDown, FaCheck, FaSpinner } from 'react-icons/fa';

export interface Options {
  label: string;
  value: string;
  isEnabled?: boolean;
}

interface DropdownProps {
  isOpen: boolean;
  selectedOption: string | null;
  options: Options[];
  onToggle: () => void;
  onOptionClick: (value: string) => void;
  disabled?: boolean; // New prop to handle disabled state
}

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  selectedOption,
  options,
  onToggle,
  onOptionClick,
  disabled = false, // Default to not disabled
}) => {
  // Prevent toggling the dropdown if disabled
  const handleToggle = () => {
    if (!disabled) {
      onToggle();
    }
  };

  return (
    <div className="relative w-80">
      <button
        type="button"
        className={clsx(
          'block w-full rounded-lg bg-white border border-gray-300 py-2 px-3 text-lg text-black flex justify-between items-center',
          'focus:outline-none focus:ring-2 focus:ring-blue-400',
          isOpen && 'ring-2 ring-blue-400',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
        onClick={handleToggle}
        disabled={disabled} // Disable the button
        aria-disabled={disabled}
      >
        <span>
          {selectedOption
            ? `Active Lab: ${options.find((option) => option.value === selectedOption)?.label}`
            : 'Lab resetting...'}
        </span>
        <FaChevronDown
          className={clsx(
            'h-5 w-5 text-black transition-transform',
            isOpen && 'transform rotate-180',
          )}
          aria-hidden="true"
        />
      </button>
      <Transition
        show={isOpen && !disabled} // Do not show dropdown if disabled
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-lg shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <li
              key={option.value}
              className={clsx(
                'py-2 px-3 text-lg text-black hover:bg-gray-200 cursor-pointer flex justify-between items-center',
                selectedOption === option.value && 'bg-primary-100',
                !option.isEnabled && 'cursor-not-allowed',
              )}
              onClick={() => option.isEnabled && onOptionClick(option.value)}
              aria-disabled={!option.isEnabled}
            >
              <span>{option.label}</span>
              {option.isEnabled ? (
                selectedOption === option.value && (
                  <FaCheck
                    className="h-4 w-4 text-blue-500"
                    aria-hidden="true"
                  />
                )
              ) : (
                <div className="flex items-center">
                  <span className="mr-2">
                    <i>Resetting</i>
                  </span>
                  <FaSpinner
                    className="h-4 w-4 animate-spin text-gray-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
};

export default Dropdown;
