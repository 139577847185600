import React from 'react';

import { useLabContext } from '../context/LabContext';
import { ResourceState } from '../models';

import DesktopArea from './DesktopArea';
import LoadingScreen from './LoadingScreen';

const Content: React.FC = () => {
  const { labSetInstance, loadingMessage } = useLabContext();

  // Only show loading bar if the lab is being created
  const loadingTimeSeconds =
    labSetInstance?.state == ResourceState.CREATING
      ? labSetInstance.loadingTimeSeconds
      : undefined;

  return (
    <div className="flex-grow flex flex-col">
      {loadingMessage ? (
        <LoadingScreen
          message={loadingMessage}
          loadingTimeSeconds={loadingTimeSeconds}
        />
      ) : (
        <DesktopArea />
      )}
    </div>
  );
};

export default Content;
