import React, { useState, useEffect } from 'react';
import { FaFileAlt, FaQuestionCircle, FaShare, FaLink } from 'react-icons/fa';

import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import LabDropdown from '../components/LabDropdown';
import WorkstationSelect from '../components/WorkstationSelect';
import { useLabContext } from '../context/LabContext';
import { ResourceState } from '../models';

import LabHelpDialog from './LabHelpDialog';
import LabInstructionsDialog from './LabInstructionsDialog';
import ShareLabDialog from './ShareLabDialog';

const LabControlHeader: React.FC = () => {
  const { labSetInstance, isJoinedLab } = useLabContext();
  const [showControls, setShowControls] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showShareLab, setShowShareLab] = useState(false);

  useEffect(() => {
    if (!labSetInstance || labSetInstance.state !== ResourceState.DEPLOYED) {
      setShowControls(false);
      return;
    }
    setShowControls(true);
  }, [labSetInstance]);

  const handleInstructionsClick = () => {
    setShowInstructions(!showInstructions);
  };

  const handleShareLabClick = () => {
    setShowShareLab(!showShareLab);
  };

  const handleHelpClick = () => {
    setShowHelp(true);
  };

  return (
    <>
      <Header>
        {showControls && (
          <>
            <LabDropdown />
            <WorkstationSelect />
            {isJoinedLab && (
              <HeaderButton
                icon={<FaLink />}
                label="Shared Lab"
                onClick={() => {}} // eslint-disable-line
                disabled={true}
              />
            )}
            {!isJoinedLab && (
              <>
                <HeaderButton
                  icon={<FaShare />}
                  label="Share"
                  onClick={handleShareLabClick}
                  disabled={false}
                />
                <HeaderButton
                  icon={<FaQuestionCircle />}
                  label="Help"
                  onClick={handleHelpClick}
                  disabled={false}
                />
              </>
            )}
            <HeaderButton
              icon={<FaFileAlt />}
              label="Instructions"
              onClick={handleInstructionsClick}
              disabled={false}
            />
          </>
        )}
      </Header>
      {!isJoinedLab && (
        <>
          <ShareLabDialog
            isOpen={showShareLab}
            onClose={() => setShowShareLab(false)}
          />
          <LabHelpDialog isOpen={showHelp} onClose={() => setShowHelp(false)} />
        </>
      )}
      <LabInstructionsDialog
        isOpen={showInstructions}
        onClose={() => setShowInstructions(false)}
      />
    </>
  );
};

export default LabControlHeader;
