export enum ResourceState {
  NEW = 'new',
  CREATING = 'creating',
  RECREATING = 'recreating',
  REBOOTING = 'rebooting',
  STARTING = 'starting',
  DEPLOYED = 'deployed',
  STOPPED = 'stopped',
  STOPPING = 'stopping',
  DESTROYING = 'destroying',
  ERRORED = 'errored',
}
