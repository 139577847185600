import React from 'react';
import { FaLock } from 'react-icons/fa';

import Header from '../components/Header';

const UnauthorizedPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="flex items-center justify-center h-screen bg-blue-600 text-white">
        <div className="text-center">
          <FaLock className="text-6xl mb-4 mx-auto" />
          <h1 className="text-5xl font-bold mb-4">Unauthorized</h1>
          <p className="text-lg">You are not authorized to view this page.</p>
        </div>
      </div>
    </>
  );
};

export default UnauthorizedPage;
