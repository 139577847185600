import React from 'react';

import { useLabDropdown } from '../hooks/useLabDropdown';

import Dropdown, { Options } from './Dropdown';

const LabDropdown: React.FC = () => {
  const {
    isOpen,
    isVisible,
    isJoinedLab,
    dropdownRef,
    selectedOption,
    options,
    onToggle,
    onOptionClick,
  } = useLabDropdown();

  if (!isVisible) return null;

  // Map your options to the Options interface expected by Dropdown
  const dropdownOptions: Options[] = options.map((option) => ({
    label: option.label,
    value: option.value,
    isEnabled: option.isEnabled !== false, // Default to enabled if not specified
  }));

  return (
    <div ref={dropdownRef}>
      <Dropdown
        isOpen={isOpen}
        selectedOption={selectedOption}
        options={dropdownOptions}
        onToggle={onToggle}
        onOptionClick={onOptionClick}
        disabled={isJoinedLab}
      />
    </div>
  );
};

export default LabDropdown;
