import React from 'react';
import { FaTools } from 'react-icons/fa';

const Recordings: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-full bg-blue-600 text-white">
      <div className="text-center">
        <FaTools className="text-6xl mb-4 mx-auto" />
        <h1 className="text-4xl font-bold mb-4">Under Construction</h1>
        <p className="text-lg">
          Work is being done to bring you this feature. Stay tuned!
        </p>
      </div>
    </div>
  );
};

export default Recordings;
