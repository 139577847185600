import clsx from 'clsx';
import React, { useState, useRef } from 'react';

import KaliIcon from '../assets/icons/kali.svg';
import LinuxIcon from '../assets/icons/linux.svg';
import RhelIcon from '../assets/icons/rhel.svg';
import UbuntuIcon from '../assets/icons/ubuntu.svg';
import UnknownIcon from '../assets/icons/unknown.svg';
import WindowsIcon from '../assets/icons/windows.svg';
import { WorkstationIcon, Workstation, ResourceState } from '../models';

const iconMap = {
  [WorkstationIcon.WINDOWS]: WindowsIcon,
  [WorkstationIcon.LINUX]: LinuxIcon,
  [WorkstationIcon.RHEL]: RhelIcon,
  [WorkstationIcon.UBUNTU]: UbuntuIcon,
  [WorkstationIcon.KALI]: KaliIcon,
};

interface WorkstationOptionProps {
  workstation: Workstation;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const WorkstationOption: React.FC<WorkstationOptionProps> = ({
  workstation,
  isActive,
  onClick,
  disabled = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const isDeployed = workstation.state === ResourceState.DEPLOYED;

  const renderIcon = (icon: string) => {
    const iconSrc = iconMap[icon as WorkstationIcon] || UnknownIcon;
    return (
      <img
        src={iconSrc}
        alt={icon}
        className="w-6 h-6 transition-transform duration-200 ease-in-out group-hover:scale-110"
        draggable={false}
      />
    );
  };

  return (
    <div ref={componentRef} className="relative">
      <div
        data-testid={workstation.workstationId}
        className={clsx(
          'group p-2 flex flex-col items-center justify-center rounded-lg transition-all duration-300 ease-in-out',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          !isDeployed
            ? 'bg-gray-200'
            : clsx(
                isActive
                  ? 'border-black shadow-lg bg-primary-200'
                  : 'bg-white hover:bg-gray-200 shadow-md',
              ),
        )}
        onClick={!disabled && isDeployed ? onClick : undefined}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {!isDeployed ? (
          <div
            data-testid="workstation-option-spinner"
            className="w-6 h-6 border-4 border-t-4 border-gray-200 border-t-black rounded-full animate-spin"
          ></div>
        ) : (
          renderIcon(workstation.icon)
        )}
      </div>
      {showTooltip && (
        <div
          className="absolute px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-lg z-50 whitespace-nowrap"
          style={{
            top: 'calc(100% + 8px)',
            right: '0',
          }}
        >
          {workstation.workstationId}
          <div
            className="absolute w-0 h-0"
            style={{
              top: '-8px',
              right: '8px',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderBottom: '8px solid #1f2937',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default WorkstationOption;
