import { useState, useEffect, useRef, useCallback } from 'react';

import { useLabContext } from '../context/LabContext';
import { ResourceState } from '../models';

interface Option {
  label: string;
  value: string;
  isEnabled?: boolean;
}

export const useLabDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { labSetInstance, activeLabId, setActiveLabId, isJoinedLab } =
    useLabContext();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const options: Option[] = labSetInstance
    ? labSetInstance.labs.map((lab) => ({
        label: lab.labId,
        value: lab.labId,
        isEnabled: lab.state === ResourceState.DEPLOYED,
      }))
    : [];

  const isVisible = options.length > 1;

  const handleSetActiveLabId = useCallback(
    (labId: string) => {
      if (activeLabId === labId || !labSetInstance) {
        return;
      }
      setActiveLabId(labId);
    },
    [activeLabId, setActiveLabId, labSetInstance],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onOptionClick = (value: string) => {
    handleSetActiveLabId(value);
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    isVisible,
    isJoinedLab,
    dropdownRef,
    selectedOption: activeLabId,
    options,
    onToggle,
    onOptionClick,
  };
};
