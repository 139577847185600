// beaker/src/context/LabContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useTheme } from '../context/ThemeContext';
import { LabSetInstance, ResourceState } from '../models';
import { WebSocketLabStateChangedData } from '../models/WebSocketMessage';

import { useToast, ToastType } from './ToastContext';
import { useWebSocket } from './WebSocketContext';

export interface LabContextState {
  labSetInstanceId: string | null;
  labSetInstance: LabSetInstance | null;
  activeLabId: string | null;
  activeWorkstation: string | null;
  loadingMessage: string;
  isJoinedLab: boolean;
  setLabSetInstanceId: (labSetInstanceId: string | null) => void;
  setLabSetInstance: (details: LabSetInstance | null) => void;
  setActiveLabId: (labId: string | null) => void;
  setActiveWorkstation: (workstationName: string | null) => void;
  setLoadingMessage: (message: string) => void;
  setIsJoinedLab: (isJoinedLab: boolean) => void;
}

export const LabContext = createContext<LabContextState | undefined>(undefined);

export const useLabContext = () => {
  const context = useContext(LabContext);
  if (!context) {
    throw new Error('useLabContext must be used within a LabProvider');
  }
  return context;
};

interface LabProviderProps {
  children: ReactNode;
}

export const LabProvider: React.FC<LabProviderProps> = ({ children }) => {
  const [labSetInstanceId, setLabSetInstanceId] = useState<string | null>(null);
  const [labSetInstance, setLabSetInstance] = useState<LabSetInstance | null>(
    null,
  );
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isJoinedLab, setIsJoinedLab] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { setTheme } = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryActiveLabId = searchParams.get('active');
  const queryActiveWorkstation = searchParams.get('workstation');

  const [activeLabId, setActiveLabIdState] = useState<string | null>(
    queryActiveLabId,
  );
  const [activeWorkstation, setActiveWorkstationState] = useState<
    string | null
  >(queryActiveWorkstation);

  const { updateLabViewState, subscribeToLabEvents, unsubscribeFromLabEvents } =
    useWebSocket();

  const isInitialLoadRef = useRef(true);
  const prevLabViewStateRef = useRef<LabSetInstance['labViewState'] | null>(
    null,
  );

  // Subscribe to lab events and handle LAB_STATE_CHANGED
  useEffect(() => {
    if (labSetInstanceId) {
      const handlers = {
        onLabStateChanged: (data: WebSocketLabStateChangedData) => {
          onLabStateChanged(data);
        },
      };

      subscribeToLabEvents(labSetInstanceId, handlers);

      return () => {
        unsubscribeFromLabEvents(labSetInstanceId);
      };
    }
  }, [labSetInstanceId]);

  const onLabStateChanged = (data: WebSocketLabStateChangedData) => {
    setLabSetInstance(data.labSetInstance);
    updateLoadingMessage(data.labSetInstance);
  };

  const updateLoadingMessage = (instance: LabSetInstance) => {
    switch (instance.state) {
      case ResourceState.DEPLOYED:
        setLoadingMessage('');
        break;
      case ResourceState.CREATING:
        setLoadingMessage('Creating your lab');
        break;
      case ResourceState.ERRORED:
        showToast(
          'An unknown error occurred. Please contact support for assistance.',
          ToastType.FAILURE,
          30000,
        );
        navigate('/');
        break;
      case ResourceState.DESTROYING:
        navigate('/');
        break;
      default:
        showToast(`Unexpected lab state: ${instance.state}`, ToastType.FAILURE);
        navigate('/');
    }
  };

  // Update activeLabId and activeWorkstation based on labSetInstance changes
  useEffect(() => {
    if (labSetInstance) {
      if (isInitialLoadRef.current) {
        // On initial load, use query parameters or labViewState
        let initialActiveLabId =
          queryActiveLabId || labSetInstance.labViewState?.activeLabId;

        if (!initialActiveLabId && labSetInstance.labs.length > 0) {
          // If no activeLabId, pick the first lab
          initialActiveLabId = labSetInstance.labs[0].labId;
        }
        setActiveLabId(initialActiveLabId || null);

        let initialActiveWorkstation =
          queryActiveWorkstation ||
          labSetInstance.labViewState?.activeWorkstationId;

        if (
          !initialActiveWorkstation &&
          labSetInstance.workstations.length > 0
        ) {
          // If no activeWorkstation, pick the first workstation
          initialActiveWorkstation =
            labSetInstance.workstations[0].workstationId;
        }
        setActiveWorkstationState(initialActiveWorkstation || null);

        isInitialLoadRef.current = false;
      } else {
        // On subsequent labSetInstance changes, update activeLabId and activeWorkstation
        const newLabViewState = labSetInstance.labViewState;
        const prevLabViewState = prevLabViewStateRef.current;

        // Active Lab
        if (
          newLabViewState?.activeLabId !== prevLabViewState?.activeLabId &&
          activeLabId !== newLabViewState?.activeLabId
        ) {
          if (newLabViewState?.activeLabId) {
            setActiveLabId(newLabViewState.activeLabId);
          } else if (labSetInstance.labs.length > 0) {
            setActiveLabId(labSetInstance.labs[0].labId);
          }
        }

        // Active Workstation
        if (
          newLabViewState?.activeWorkstationId !==
            prevLabViewState?.activeWorkstationId &&
          activeWorkstation !== newLabViewState?.activeWorkstationId
        ) {
          if (newLabViewState?.activeWorkstationId) {
            setActiveWorkstationState(newLabViewState.activeWorkstationId);
          } else if (labSetInstance.workstations.length > 0) {
            setActiveWorkstationState(
              labSetInstance.workstations[0].workstationId,
            );
          }
        }

        prevLabViewStateRef.current = newLabViewState;
      }
    }
  }, [labSetInstance]);

  // Update backend labViewState when activeLabId or activeWorkstation changes
  useEffect(() => {
    if (isJoinedLab) {
      // Do not attempt to update lab view state if we are joined to this lab
      return;
    }

    if (labSetInstance) {
      const newLabViewState = {
        ...labSetInstance.labViewState,
        activeLabId,
        activeWorkstationId: activeWorkstation,
      };

      updateLabViewState(labSetInstance.labSetInstanceId, newLabViewState);
    }
  }, [activeLabId, activeWorkstation, labSetInstance, isJoinedLab]);

  // Update query params when activeLabId or activeWorkstation changes
  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (activeLabId) {
      newSearchParams.set('active', activeLabId);
    } else {
      newSearchParams.delete('active');
    }

    if (activeWorkstation) {
      newSearchParams.set('workstation', activeWorkstation);
    } else {
      newSearchParams.delete('workstation');
    }

    setSearchParams(newSearchParams);
  }, [activeLabId, activeWorkstation]);

  // Ensure activeLabId is valid or set default when labs change
  useEffect(() => {
    if (labSetInstance && labSetInstance.labs.length > 0) {
      const activeLab = labSetInstance.labs.find(
        (lab) => lab.labId === activeLabId,
      );

      const deployedLabs = labSetInstance.labs.filter(
        (lab) => lab.state === ResourceState.DEPLOYED,
      );

      if (!activeLab || activeLab.state !== ResourceState.DEPLOYED) {
        if (deployedLabs.length > 0) {
          setActiveLabId(deployedLabs[0].labId);
        } else {
          // No labs are in DEPLOYED state
          setActiveLabId(null);
        }
      }
    }
  }, [labSetInstance?.labs, activeLabId]);

  // Ensure activeWorkstation is valid or set default when workstations change
  useEffect(() => {
    if (labSetInstance && labSetInstance.workstations.length > 0) {
      if (
        !activeWorkstation ||
        !labSetInstance.workstations.some(
          (ws) => ws.workstationId === activeWorkstation,
        )
      ) {
        setActiveWorkstationState(labSetInstance.workstations[0].workstationId);
      }
    }
  }, [labSetInstance?.workstations, activeWorkstation]);

  const setActiveLabId = (labId: string | null) => {
    attemptSetTheme(labId);
    setActiveLabIdState(labId);
  };

  const attemptSetTheme = (labId: string | null) => {
    if (labSetInstance) {
      const newLab = labSetInstance.labs.find((lab) => lab.labId === labId);
      setTheme(newLab?.uiTheme || 'default');
    } else {
      setTheme('default');
    }
  };

  const setActiveWorkstation = (workstationId: string | null) => {
    setActiveWorkstationState(workstationId);
  };

  const contextValue: LabContextState = {
    labSetInstanceId,
    labSetInstance,
    activeLabId,
    activeWorkstation,
    loadingMessage,
    isJoinedLab,
    setLabSetInstanceId,
    setLabSetInstance,
    setActiveLabId,
    setActiveWorkstation,
    setLoadingMessage,
    setIsJoinedLab,
  };

  return (
    <LabContext.Provider value={contextValue}>{children}</LabContext.Provider>
  );
};
