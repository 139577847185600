import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Header from '../components/Header';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="flex items-center justify-center h-screen bg-blue-900 text-white">
        <div className="text-center">
          <FaExclamationTriangle className="text-6xl mb-4 mx-auto text-yellow-400" />
          <h1 className="text-5xl font-bold mb-4">404 - Page Not Found</h1>
          <p className="mb-6 text-lg text-blue-300">
            Sorry, the page you are looking for does not exist.
          </p>
          <Link
            to="/"
            className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-600 transition flex items-center justify-center mx-auto"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
