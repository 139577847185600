import axios from 'axios';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { useToast, ToastType } from '../context/ToastContext';
import { JoinableLabEntry } from '../models';
import { GuacamoleTokenDetails } from '../models/GuacamoleTokenDetails';
import { LabSetInstance } from '../models/LabSetInstance';

const HELP_SUFFIX = 'Please contact the Cyber Range team for support.';

export class NotFoundError extends Error {
  constructor() {
    super('Not found.');
    this.name = 'NotFoundError';
  }
}

export class BadRequestError extends Error {
  constructor() {
    super('Bad request.');
    this.name = 'BadRequestError';
  }
}

export class TooManyRequestsError extends Error {
  constructor() {
    super('Too many requests.');
    this.name = 'TooManyRequestsError';
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized.');
    this.name = 'UnauthorizedError';
  }
}

const useApiClient = () => {
  const { token } = useContext<IAuthContext>(AuthContext);
  const { showToast } = useToast();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || '',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  apiClient.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  const handleError = (error: any) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new BadRequestError();
        case 403:
          throw new UnauthorizedError();
        case 404:
          throw new NotFoundError();
        case 429:
          throw new TooManyRequestsError();
        default:
          break;
      }
    }

    const msg = `An unknown error occurred. ${HELP_SUFFIX}`;
    showToast(msg, ToastType.FAILURE, 10000);
    console.error(error);
    throw error;
  };

  const apiCall = async (method: 'get' | 'post', url: string, data?: any) => {
    try {
      const response = await apiClient[method](url, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  const createLabSetInstance = (labSetId: string): Promise<LabSetInstance> =>
    apiCall('post', `/self-service/${labSetId}`);

  const resetLabResource = (resetUrl: string): Promise<void | null> =>
    apiCall('get', resetUrl);

  const deleteLabSetInstance = (labSetId: string): Promise<void | null> =>
    apiCall('get', `/self-service/destroy/${labSetId}`);

  const findLabSetInstance = (labSetId: string): Promise<LabSetInstance> =>
    apiCall('get', `/self-service/find/${labSetId}`);

  const getLabSetInstances = (): Promise<LabSetInstance[]> =>
    apiCall('get', '/self-service/lab-set-instances');

  const extendLabTime = (labSetId: string): Promise<null> =>
    apiCall('get', `/self-service/extend-expiration/${labSetId}`);

  const getGuacamoleTokenDetailsForLab = (
    labSetId: string,
    join = false,
  ): Promise<GuacamoleTokenDetails | null> =>
    apiCall('get', `/self-service/guacamole-token/${labSetId}?join=${join}`);

  const shareLab = (
    labSetInstanceId: string,
    readOnly: boolean,
  ): Promise<JoinableLabEntry> =>
    apiCall('post', '/self-service/join-code', {
      labSetInstanceId,
      readOnly,
    });

  return {
    createLabSetInstance,
    resetLabResource,
    deleteLabSetInstance,
    findLabSetInstance,
    getLabSetInstances,
    extendLabTime,
    getGuacamoleTokenDetailsForLab,
    shareLab,
  };
};

export default useApiClient;
