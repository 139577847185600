import React, { useEffect, useState, useRef } from 'react';

import useApiClient from '../api/apiClient';
import { useLabContext } from '../context/LabContext';
import { ResourceState } from '../models';
import { GuacamoleTokenDetails } from '../models/GuacamoleTokenDetails';

import ExtendTimeDialog from './ExtendTimeDialog';
import GuacamoleConnection from './GuacamoleConnection';

const DesktopArea: React.FC = () => {
  const { labSetInstance, activeWorkstation, isJoinedLab } = useLabContext();
  const [guacamoleToken, setGuacamoleToken] = useState<string | null>(null);
  const { getGuacamoleTokenDetailsForLab } = useApiClient();

  const previousLabSetInstanceStateRef = useRef<ResourceState | null>(null);

  useEffect(() => {
    if (
      labSetInstance?.state === ResourceState.DEPLOYED &&
      previousLabSetInstanceStateRef.current !== ResourceState.DEPLOYED
    ) {
      previousLabSetInstanceStateRef.current = labSetInstance.state;

      const fetchToken = async () => {
        const tokenDetails = await getGuacamoleTokenDetailsForLab(
          labSetInstance.labSetInstanceId,
          isJoinedLab,
        );

        if (!tokenDetails) {
          return;
        }

        setGuacamoleToken(tokenDetails.token);
        setLoadBalancerDomainCookie(tokenDetails);
      };

      fetchToken();
    } else {
      previousLabSetInstanceStateRef.current = labSetInstance?.state ?? null;
    }
  }, [labSetInstance]);

  const setLoadBalancerDomainCookie = (
    token_details: GuacamoleTokenDetails,
  ) => {
    // Note: this cookie is needed to ensure that the backend Guacamole instance
    // that services requests from the browser is the same as the instance that
    // originally generated the token in the lab manager backend. We rely on the
    // fact that the Lab UI is at labs.mantarange.com and the Guacamole portal is at
    // portal.mantarange.com. This allows labs.mantarange.com to set a cookie for
    // .mantarange.com which is then usable by portal.mantarange.com via browser's
    // same-origin policy. We have observed that Chrome, Firefox, Safari, and Edge
    // will all use the latest cookie value for a given cookie name, regardless of
    // the domain that set the cookie. By setting the cookie for .mantarange.com before
    // the iframe is loaded, we ensure that the guacamole iframe will use the correct
    // cookie for its first request, meaning that the initial request is sent
    // to the same backend instance that generated the token.
    const cookie_name = token_details.loadBalancerCookieName;
    const cookie_value = token_details.loadBalancerCookieValue;
    document.cookie = `${cookie_name}=${cookie_value}; domain=.mantarange.com`;
  };

  return (
    <div>
      <GuacamoleConnection
        guacamoleBaseUrl={labSetInstance?.guacamoleUrl}
        token={guacamoleToken}
        workstationId={activeWorkstation}
      />
      <ExtendTimeDialog />
    </div>
  );
};

export default DesktopArea;
